import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import SEO from '../../components/seo'
import Title from '../../components/title'
import Header from '../../components/header'
import Container from '../../components/container'
import FeatureBlock from '../../components/feature-block'
import Layout from '../../layout'
import Image from '../../components/image'
import Brands from '../../components/brands'
import AnimatedArticle from '../../components/animated-article'
import AnimatedAside from '../../components/animated-aside'
import P from '../../components/paragraph'
import BrandBadge from '../../components/brand-badge'

const ShlPage = () => {
  const images = useStaticQuery(graphql`
    query {
      bottle: file(relativePath: { eq: "brands/shl/shl-bottle.png" }) {
        childImageSharp {
          fluid(maxWidth: 770) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      group: file(relativePath: { eq: "brands/shl/shl-group.png" }) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO
        title='Stephane Humbert Lucas'
        description={`Stephane Humbert Lucas Paris is a field of expression.  Each creation starts with a blank canvas where touches of colour, musical notes, poetry and images will be progressively inscribed. The permanent inspiration drawn from painting, literature and music sets the tone and imposes a selection of the most beautiful raw materials of fine perfumery. The process can finally begin. Both the harmony and the roughness are beautifully confronted as a result of the creator’s need to place perfumery as a major art.`}
      />
      <Header />

      <Container wide noPadding>
        <FeatureBlock>
          <AnimatedArticle onLoad badge>
            <Title fancy>Stephane Humbert Lucas</Title>
            <P>
              Stephane Humbert Lucas Paris is a field of expression. Each
              creation starts with a blank canvas where touches of colour,
              musical notes, poetry and images will be progressively inscribed.
            </P>
            <BrandBadge brand='shl' />
          </AnimatedArticle>
          <AnimatedAside>
            <Image
              css={`
                @media (min-width: ${({ theme }) => theme.breakpoint.large}) {
                  margin-top: 100px;
                }
              `}
              fluid={images.bottle.childImageSharp.fluid}
              notch='bottomLeftSmall'
              alt='shl bottle'
            />
          </AnimatedAside>
        </FeatureBlock>
      </Container>
      <Container width={1100} noPadding center overflow css='margin-top: -50px'>
        <FeatureBlock grey={false} center>
          <AnimatedAside right>
            <Image
              fluid={images.group.childImageSharp.fluid}
              shadow={false}
              alt='shl perfume bottle'
              css={`
                max-width: 600px;
                @media (max-width: ${({ theme }) => theme.breakpoint.large}) {
                  max-width: 250px;
                  margin: 0 auto;
                }
              `}
            />
          </AnimatedAside>
          <AnimatedArticle>
            <P css='max-width: 500px;'>
              The permanent inspiration drawn from painting, literature and
              music sets the tone and imposes a selection of the most beautiful
              raw materials of fine perfumery. The process can finally begin.
              Both the harmony and the roughness are beautifully confronted as a
              result of the creator’s need to place perfumery as a major art.
            </P>
          </AnimatedArticle>
        </FeatureBlock>
      </Container>
      <Container width={1200}>
        <Title fancy>Other Brands</Title>
        <Brands />
      </Container>
    </Layout>
  )
}

export default ShlPage
